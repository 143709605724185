import React from "react";
import { Oval } from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";
import { ReactComponent as SVGlogo } from "../../assets/logo.svg";

const LoadingIndicator = ({ hasLogo }) => {
  const { promiseInProgress } = usePromiseTracker();

  if (!promiseInProgress) return null;
  return (
    <div className="LoadingIndicator">
      {hasLogo && <SVGlogo className="LoadingIndicator__logo" />}
      <Oval
        ariaLabel="loading"
        color="#3BAEC9"
        height={80}
        secondaryColor="#3BAEC9"
        strokeWidth={2}
        strokeWidthSecondary={2}
        visible={true}
        width={80}
      />
    </div>
  );
};

export default LoadingIndicator;
